/*
 * @Author: Nanxing Xiang
 * @Date: 2021-03-03 15:01:29
 * @LastEditors: Nanxing Xiang
 * @LastEditTime: 2021-05-14 15:25:50
 * @Description: file content
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import remfix from '@easyvaas/rem-fixable';

remfix(750, 75);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
