/*
 * @Author: Nanxing Xiang
 * @Date: 2021-03-03 15:01:29
 * @LastEditors: Nanxing Xiang
 * @LastEditTime: 2021-11-01 11:08:38
 * @Description: file content
 */
import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { EasErrorBoundary, LoadingPage } from '@easyvaas/react-components';
import createReactRoutes from '@easyvaas/create-react-routes';

const Routes = createReactRoutes({
  'furolive/live': lazy(() => import('./pages/live')),
  'furolive/dark': lazy(() => import('./pages/dark')),
  'furolive/light': lazy(() => import('./pages/light')),
  'furolive/slide': lazy(() => import('./pages/slide')),
  'furolive/swiper': lazy(() => import('./pages/swiper')),
  blank: lazy(() => import('./pages/blank'))
});

const App: React.FC = () => {
  return (
    <EasErrorBoundary>
      <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
        <Suspense fallback={<LoadingPage type="furolive" />}>
          <Routes />
        </Suspense>
      </BrowserRouter>
    </EasErrorBoundary>
  );
};
export default App;
